/* checkbox-slide css */
.checkbox-slide-wrapper {
    width: 66px;
    height: 33px;
    border-radius: 30px;
    overflow: hidden;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* margin-left: auto; */
}

.checkbox-slide {
    opacity: 0;
    position: absolute;
    width: 66px;
    height: 33px;
    cursor: pointer;
    z-index: 2;
}

label[for^="checkbox-slide-"] { /* Targets all labels for checkboxes with IDs starting with 'checkbox-slide-' */
    display: block;
    width: 100%;
    height: 100%;
    background: #ad795b84;
    position: relative;
    transition: 250ms;
    cursor: pointer;
}

label[for^="checkbox-slide-"]:before {
    content: "";
    width: 28px;
    height: 28px;
    background: #544541;
    position: absolute;
    border-radius: 100%;
    inset: 0;
    margin: auto 0px;
    left: 4px;
    transition: ease-in 250ms;
}

.checkbox-slide:checked + label:before {
    left: 34px;
    background: #544541;
}

.checkbox-slide:checked + label {
    background: #ad795b84;
}


/* checkbox-slide css */
