#root {
  background-color: #F9F8F6; /* Sets the background color for the root element */
  min-height: 100vh; /* Make sure it covers the full viewport height */
  width: 100%; /* Ensure it covers the full viewport width */
}

h1 {
  font-family: 'Yanone Kaffeesatz';
  font-size: 75px;
}

h2 {
  font-family: 'Yanone Kaffeesatz';
  font-size: 55px;
}

h3 {
  font-family: 'Yanone Kaffeesatz';
  font-size: 26px;
}

h4 {
  font-family: 'Yanone Kaffeesatz';
  font-size: 18px;
}

p {
  font-family: 'Yanone Kaffeesatz';
}

label {
  font-family: 'Yanone Kaffeesatz';
}

button {
  font-family: 'Yanone Kaffeesatz';
}

.cta-info {
  font-size: 30px;
}

.bold {
  font-weight: bold;
}

.brown-font {
  color: #544541;
}

.light-brown-font {
  color: #AD795B;
}

.dark-green-font {
  color: #4B6741;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.small-padding {
  padding: 10px
}


.medium-padding {
  padding: 15px
}

.small-margin {
  margin: 10px
}


.medium-margin {
  margin: 15px
}

@media (max-width: 800px) {
  h1 {
    font-size: 70px;
    margin-top: 0px;
  }

  h2 {
    font-size: 50px;
  }
}


@media (max-width: 500px) {
  h1 {
    font-size: 50px;
    margin-top: 0px;
  }

  h2 {
    font-size: 36px;
  }
}

@media (max-width: 400px) {
  h1 {
    font-size: 40px;
    margin-top: 0px;
  }

  h2 {
    font-size: 30px;
  }
}