.homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #f0ede5;  */
    padding-left: 50px;
    padding-right: 50px;
  }
  
  .logo-section {
    margin-bottom: 20px;
  }
  
  .logo {
    /* Adjust the size as needed */
    width: 150px;
    height: auto;
  }
  
  .headline-section h1 {
    text-align: center;
  }
  
  .coming-soon {
    text-align: center;
    margin-top: 10px;
  }
  
  .cta-section {
    margin-top: 30px;
    text-align: center;
  }

  
  .email-input {
    /* padding: 10px; */
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .submit-button {
    background-color: #4caf50; /* Green background for the button */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #45a049; /* Slightly darker on hover */
  }

  .cta-label {
    /* Set the label to be a flex container to align items in a row */
    display: flex;
    /* align-items: center;  */
    justify-content: center;
    font-size: 45px;
  }

  .cta-button {
    border: 2px solid black;  /* Black border */
    border-radius: 20px;      /* Curved edges */
    padding: 10px 20px;       /* Padding for some space inside the button */
    background-color: #4B6741;  /* Background color */
    color: white;             /* Text color */
    font-size: 24px;          /* Font size */
    cursor: pointer;          /* Cursor changes to pointer on hover */
    transition: all 0.3s;     /* Smooth transition for any changes */
    width: 30vw;
  }
  
  .cta-button:hover {
    background-color: #79a668;  /* Background color on hover */
    color: black;             /* Text color on hover */
  }

  @media (max-width: 800px) {
    .cta-label {
        font-size: 36px;
      }

      .cta-button {
        width: 40vw;
      }
  }

  @media (max-width: 500px) {
    .cta-label {
        font-size: 20px;
      }
  }
  
  @media (max-width: 400px) {
    .cta-label {
        font-size: 20px;
      }
  }
  
  

  /* .cta-label span {
    position: absolute;
    transition: top 0.5s ease-out, opacity 0.5s ease-out;
    opacity: 0;
  }
  
  .visible {
    opacity: 1 !important;
    top: 0;
  }
  
  .hidden {
    opacity: 0 !important;
    top: -20px;
  } */

  /* animations for sliding text */
  /* @keyframes slideUp {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-100%);
      opacity: 0;
    }
  }
  
  @keyframes slideDown {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .slide-up {
    animation: slideUp 0.5s ease-out forwards;
  }
  
  .slide-down {
    animation: slideDown 0.5s ease-out forwards;
  }
  
   */